<template>
    <el-container style="height:100%">
        <el-aside width="400px" style="padding:5px">
            <el-input placeholder="输入关键字进行过滤" size="small" v-model="filterText"></el-input>
            <el-tree ref="tree"
                     :data="treeData"
                     :props="defaultProps"
                     node-key="id"
                     @node-click="handleNodeClick"
                     :default-expanded-keys="expanded"
                     :filter-node-method="filterNode"
                     :expand-on-click-node="false"
                     highlight-current>
                <template slot-scope="{ node, data }">
                    <span>
                        {{ data.name }}(编码：{{data.fenLeiBM}})({{ data.subNodes ? data.subNodes.length : 0 }})
                    </span>
                </template>
            </el-tree>
        </el-aside>
        <el-container>
            <el-header style="height: 50px;line-height: 45px;padding: 0;">
                <el-button type="primary" size="small" @click="handleSave" icon="el-icon-document-add">保 存</el-button>
                <el-button type="primary" size="small" @click="handleAddFirst" icon="el-icon-plus">添加顶级</el-button>
                <el-button v-show="nextShow" type="primary" size="small" @click="handleNext" icon="el-icon-plus">添加下级
                </el-button>
                <el-popconfirm style="margin-left:9px;" v-show="nextShow" title="删除当前节点和所有孙节点，数据不可恢复，请确认是否删除"
                               @confirm="handleDel">
                    <el-button slot="reference" type="danger" size="small" icon="el-icon-delete">删除当前分类和所有子孙节点
                    </el-button>
                </el-popconfirm>
            </el-header>
            <el-main style="padding-right:5px;">
                <el-form ref="form" :model="form" :rules="rules" class="x-edit" style="white-space: normal">
                    <el-form-item label="分类名称" prop="name" class="w50">
                        <el-input  v-model="form.name" size="small"/>
                    </el-form-item>
                    <el-form-item label="分类编码" prop="fenLeiBM" class="w50">
                        <el-input  v-model="form.fenLeiBM" size="small"/>
                    </el-form-item>
                    <el-form-item label="所属行业" prop="suoShuXingYe" class="w50">
<!--                        <el-input :disabled="hangYeDisabled" v-model="form.suoShuXingYe" size="small"/>-->
                        <el-select :disabled="hangYeDisabled" v-model="form.suoShuXingYe" size="small">
                            <el-option v-for="item in options" :key="item.id" :label="item.xingYeMC"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="树结构层级" prop="level" class="w50">
                        <el-input disabled v-model="form.level" size="small"/>
                    </el-form-item>
                    <el-form-item label="同级排序" prop="sort" class="w50">
                        <el-input-number v-model.number="form.sort" size="small" :min=0 :max=1000
                                         :step=1></el-input-number>
                    </el-form-item>
                </el-form>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import CityPicker from '@/components/CityPicker';
    import {ruleBuilder} from '@/util/validate';
    import * as service from '@/service/spgl/ShangPinFenLei';
    import XTreeBase from "@/components/x/XTreeBase";
    import {deepCopy} from "@/util/objects";
    import {getSelectList} from '@/service/mdgl/MenDianHangYe'

    export default {
        name: "ShangPinFenLeiList",
        mixins: [XTreeBase],
        components: {CityPicker},
        beforeCreate() {
            this.treeService = service.mainTree;
            this.addService = service.add;
            this.updateService = service.update;
            this.delService = service.del;
            let {required} = ruleBuilder;
            this.rules = {
                name: [required()],
                level: [required()],
                fenLeiBM:[required()],
                suoShuXingYe:[required()]
            };
            this.defaultModel = {
                id: "",
                parentId: "0",
                name: "",
                level: 1,
                sort: 0,
                fenLeiBM:'',
                suoShuXingYe:'',
                suoShuMenDian:'',
                shiFuShanChu:''
            }
        },
        data() {
            return {
                hangYeDisabled:false,
                form: deepCopy(this.defaultModel),
                options:[],
            };
        },
        mounted() {
            this.getHangYeSelector()
        },
        methods: {
            handleRefreshTree() {
                this.defaultRefreshTree();
            },
            handleNodeClick(data) {
                this.defaultNodeClick(data);
            },
            handleSave() {
                this.defaultSave().then((response) => {
                    this.handleRefreshTree();
                })
            },
            handleAddFirst() {
                this.hangYeDisabled = false
                this.defaultToAdd();
            },
            handleNext() {
                this.hangYeDisabled = true
                let parentId = this.form.id;
                let level = this.form.level + 1;
                let suoShuMenDian = this.form.suoShuMenDian
                let suoShuXingYe = this.form.suoShuXingYe
                if(level>3){
                    this.$message({
                        message:'商品分类最多只能建立三级，无法建立下级',
                        type:'warning'
                    })
                }else{
                    this.defaultToAdd();
                    this.form.parentId = parentId;
                    this.form.level = level;
                    this.form.suoShuMenDian = suoShuMenDian
                    this.form.suoShuXingYe = suoShuXingYe
                }
            },
            handleDel() {
                this.defaultDel().then((response) => {
                    this.handleRefreshTree();
                    this.defaultToAdd();
                })
            },
            getHangYeSelector(){
                getSelectList().then((response)=>{
                    this.options = response.data
                })
            }
        }
    };
</script>

<style scoped>

</style>
